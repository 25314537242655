.Toastify__toast {
    min-height: 1px;
}

.fail-products-delete .h-8 {
    height: 32px;
}

.fail-products-delete .Toastify__close-button {
    right: 24px;
}

.d-ruby {
    display: ruby;
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-text-color-light: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-icon-color-info: var(--toastify-text-color-info);
    --toastify-icon-color-success: var(--toastify-text-color-success);
    --toastify-icon-color-warning: var(--toastify-text-color-warning);
    --toastify-icon-color-error: var(--toastify-text-color-error);

    --toastify-color-progress-info: var(--toastify-text-color-info);
    --toastify-color-progress-success: var(--toastify-text-color-success);
    --toastify-color-progress-warning: var(--toastify-text-color-warning);
    --toastify-color-progress-error: var(--toastify-text-color-error);
}

.Toastify__close-button {
    color: var(--toastify-color-light);
    position: absolute;
    right: 9px;
}

.Toastify__close-button--light {
    opacity: 0.7;
}
